<template>
  <div class="footer-custom">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col text-start m-0 p-0">
          <img v-if="HasProducerLogo" class="logo-custom" src="/img/mexipass-logo-large.png">
        </div>
        <div class="col text-center m-0 p-0">
          <a target="_blank" href="https://www.bbb.org/us/ca/pasadena/profile/insurance-companies/mexipass-international-insurance-services-llc-1216-13132294">
            <img class="bbb-custom" src="/img/bbb.png">
          </a>
        </div>
        <div class="col text-end m-0 p-0">
          <div class="copyright-custom">&copy; {{year}} MexiPass</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .footer-custom {
    position: absolute;
    bottom: 0;
    border-top: 1px solid #ddf;
    background: #f9f9f9;
    min-height: 44px;
    width: 100%;
    line-height: 0px;
    padding: 0px;
  }

  .logo-custom {
    position: relative;
    top: 2px;
    left: 2px;
    width: 35vw;
    max-width: 122px;
  }

  .bbb-custom {
    position: relative;
    top: 9px;
  }

  .copyright-custom {
    white-space: nowrap;
    position: relative;
    top: 21px;
    left: -5px;
    font-size: 12px;
  }
</style>

<script>
  import lib from '@/lib'

  import { mapFields } from 'vuex-map-fields'

  export default {
    props: ['DataLayer'],

    mounted: function() {
      if ( this.DataLayer!=false ) {
        lib.gaDataLayer({'event': 'optimize.AppFooter', 'locale': this.$i18n.locale})
      }
    },

    computed: {
      ...mapFields([
        'HasProducerLogo',
      ]),

      year: function() {
        return new Date().getFullYear()
      }
    },
  }
</script>
