import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      name: 'quote1',
      path: '/:producer/',
      component: () => import('@/views/Quote1')
    }, {
      notForStamp: true,
      name: 'quote3',
      path: '/:producer/quote3',
      component: () => import('@/views/Quote3')
    }, {
      name: 'vehicle1',
      path: '/:producer/vehicle1',
      component: () => import('@/views/Vehicle1')
    }, {
      name: 'vehicle2',
      path: '/:producer/vehicle2',
      component: () => import('@/views/Vehicle2')
    }, {
      name: 'applicant1',
      path: '/:producer/applicant1',
      component: () => import('@/views/Applicant1')
    }, {
      name: 'applicant2',
      path: '/:producer/applicant2',
      component: () => import('@/views/Applicant2')
    },
    {
      name: 'driverinfo1',
      path: '/:producer/driverinfo1',
      component: () => import('@/views/DriverInfo1')
    }, {
      name: 'driverinfo2',
      path: '/:producer/driverinfo2',
      component: () => import('@/views/DriverInfo2')
    }, {
      onlyIfNotLiabilityOnly: true,
      name: 'lienholder',
      path: '/:producer/lienholder',
      component: () => import('@/views/Lienholder')
    }, {
      name: 'coverage-and-payment1',
      path: '/:producer/coverage-and-payment1',
      component: () => import('@/views/CoverageAndPayment1')
    }, {
      name: 'coverage-and-payment3',
      path: '/:producer/coverage-and-payment3',
      component: () => import('@/views/CoverageAndPayment3')
    }, {
      name: 'confirmation',
      path: '/:producer/confirmation',
      component: () => import('@/views/Confirmation')
    }, {
      name: 'es',
      path: '/:producer/es',
      component: () => import('@/views/Spanish')
    }, {
      name: 'stamp',
      path: '/:producer/stamp',
      component: () => import('@/views/Stamp')
    },
  ]
})
