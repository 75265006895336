import Vue from 'vue'
import Vuex from 'vuex'
import { getField, updateField } from 'vuex-map-fields'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Config: null,

    HasProducerLogo: false,

    Program: '',

    VehiclePrimarilyInMexico: undefined,

    InactiveSeconds: 0,

    GaPrice: 0,
    GaRevenue: 0,

    InsuranceCompany: '',

    Producer: {},

    States: [],
    Vehicles: [],

    DaysOfCoverage: [],
    VehicleValues: [],
    LiabilityLimits: [],
    VehicleYears: [],

    LimitTable: [],

    XDaysCost: '',
    YearCost: '',

    SelectedDaysOfCoverage: '',
    SelectedVehicleValue: '',
    SelectedLiabilityLimit: '',

    VehicleVin: '',
    VehicleYear: '',
    VehicleMake: '',
    VehicleModel: '',
    VehicleType: '',

    TermsAndConditionsAccepted: false,

    SelectedVehicleCountry: '',
    SelectedVehicleState: '',
    SelectedDriverInfoCountry: '',
    SelectedDriverInfoState: '',

    DriverInfoFirstName: '',
    DriverInfoLastName: '',
    DriverInfoDateOfBirth: '',

    ApplicantFirstName: '',
    ApplicantLastName: '',
    ApplicantDateOfBirth: '',
    ApplicantAddress: '',
    ApplicantAddressGoogle: null,
    ApplicantUnit: '',
    ApplicantEmail: '',
    ApplicantPhone: '',

    CoverageAndPaymentStartDate: '',

    LienholderSelection: '',
    LienholderName: '',
    LienholderCountry: '',
    LienholderAddress1: '',
    LienholderAddress2: '',
    LienholderCity: '',
    LienholderState: '',
    LienholderPostalCode: '',

    DriversLicense: '',
    LicensePlate: '',

    RentalVehicle: undefined,
    FullCoverageInUSA: undefined,
    AllDriversInAgeRange: undefined,

    PaymentCreditCard: '',
    PaymentCVC: '',
    PaymentMM: '',
    PaymentYY: '',
    PaymentFirstAndLastName: '',
    PaymentLastName: '',
    PaymentZip: '',

    PaymentPolicyNumber: '',
    PaymentEffectiveDate: '',
    PaymentExpirationDate: '',
    PaymentDocuments: [],

    Processing: false,

    RaterApplicationId: '',
    RaterVehicleId: '',
  },

  mutations: {
    updateField,
  },

  getters: {
    getField,
  },
})
