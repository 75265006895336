<template>
  <div class="center1" v-if="processing" v-bind:class="{ 'spinner' : processing }" v-bind:style="{ 'border-top-color': color, width: size, height: size }"></div>
</template>

<style scoped lang="scss">
  .spinner {
    width: 24px;
    height: 24px;
    margin: 0;
    background: transparent;
    border-top: 4px solid #47f;
    border-right: 4px solid transparent;
    border-radius: 50%;
    -webkit-animation: 1s spin linear infinite;
    animation: 1s spin linear infinite;
  }
      
  @-webkit-keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
            
  @keyframes spin {
    from {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
</style>

<script>
  import lib from '@/lib'

  export default {
    props: ['processing', 'color', 'size', 'DataLayer'],

    mounted: function() {
      if ( this.DataLayer!=false ) {
        lib.gaDataLayer({'event': 'optimize.Spinner', 'locale': this.$i18n.locale})
      }
    }
  }
</script>
