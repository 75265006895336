<template>
  <div class="modal fade" ref="dialog" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-danger text-light">
          <h5 class="modal-title">{{$t('alert-dialog.title')}}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body bg-light text-dark">
          <div class="container-fluid">
            <div class="row justify-content-center md-12">
              <div class="col text-center" v-html="message" />
            </div>
          </div>
        </div>
        <div class="modal-footer w-100 bg-light text-dark">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <button type="button" class="btn btn-primary cool-primary-gradient mt-12 w-100" data-bs-dismiss="modal">{{$t('alert-dialog.close')}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import lib from '@/lib'

  export default {
    props: ['value', 'message', 'DataLayer'],

    data: function() {
      return {
        visible: false,
        modal: null,
      }
    },

    mounted: function() {
      if ( this.DataLayer!=false ) {
        lib.gaDataLayer({'event': 'optimize.AlertDialog', 'locale': this.$i18n.locale})
      }

      /*global bootstrap*/
      /*eslint no-undef: "error"*/
      this.modal = new bootstrap.Modal(this.$refs.dialog)
      this.$refs.dialog.addEventListener('hidden.bs.modal', () => {
        this.visible = false
        this.$emit('input', this.visible)
      })
    },

    watch: {
      value: {
        handler: function() {
          this.showHide(this.value)
        },
        immediate: true
      },
    },

    methods: {
      showHide: function(status) {
        this.$emit('input', this.visible = status)
        if ( this.modal ) {
          if (this.visible) {
            this.modal.show()
          } else {
            this.modal.hide()
          }
        }
      },
    },
  }
</script>
