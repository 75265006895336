<template>
  <div class="modal fade" ref="dialog" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header bg-info text-light">
          <h5 class="modal-title">{{$t(title)}}</h5>
          <button type="button" class="btn-close btn-close-white" data-bs-dismiss="modal"></button>
        </div>
        <div class="modal-body bg-light text-dark">
          <div class="container-fluid">
            <div class="row justify-content-center md-12">
              <div class="col text-center">

                <vue-editor class="custom custom-normal"
                  v-model="content"
                  :disabled="true"
                  :editorOptions="{ modules: { toolbar: false } }" />
                  
              </div>
            </div>
            <div class="row justify-content-center" v-if="contact===true">
              <div class="col text-start">
                <div class="container-fluid m-0 p-0">
                  <div class="row justify-content-center">
                    <div class="col text-start">
                      <label class="col-form-label">{{$t('rtf-dialog.contact-label')}}</label>
                    </div>
                  </div>
                  <div class="row justify-content-center">
                    <div class="col text-start">

                      <div class="input-group">
                        <input type="text" class="form-control" autocomplete="off" :readonly="contactRequested"
                          :placeholder="$t('rtf-dialog.contact-name')"
                          v-model="contactName"
                          maxlength="200" />
                      </div>

                    </div>
                    <div class="col text-start">

                      <div class="input-group">
                        <input type="text" class="form-control" autocomplete="off" :readonly="contactRequested"
                          :placeholder="$t('rtf-dialog.contact-placeholder')"
                          v-model="contactEmailOrPhone"
                          maxlength="200" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row justify-content-center" v-if="contactRequested">
              <div class="col text-start">
                <strong><i class="warning-custom" />{{$t('rtf-dialog.contact-requested')}}</strong>
              </div>
            </div>
            <div class="row justify-content-center" v-if="badContact">
              <div class="col text-start">
                <strong><i class="warning-custom" />{{$t('rtf-dialog.bad-contact')}}</strong>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer w-100 bg-light text-dark">
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col text-center">
                <button class="btn btn-success text-center mt-12 cool-success-gradient w-100" type="button" data-bs-dismiss="modal" @click="closeAction">{{closeText}}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  .custom {
    min-height: 100px;
    height: 200px;
    max-height: 300px;
  }

  .custom-normal {
    margin-bottom: 0px;
  }
</style>

<script>
  import lib from '@/lib'

  import jslib from '@mexipassit/jslib'
  import { mapFields } from 'vuex-map-fields'
  import { VueEditor } from "vue2-editor"

  export default {
    components: {
      VueEditor,
    },

    props: ['value', 'title', 'body', 'close', 'ArgumentObject', 'contact', 'DataLayer'],

    data: function() {
      return {
        visible: false,
        content: '',
        contactName: '',
        contactEmailOrPhone: '',
        badContact: false,
        contactRequested: false,
        modal: null,
      }
    },

    mounted: function() {
      if ( this.DataLayer!=false ) {
        lib.gaDataLayer({'event': 'optimize.RtfDialog', 'locale': this.$i18n.locale})
      }

      /*global bootstrap*/
      /*eslint no-undef: "error"*/
      this.modal = new bootstrap.Modal(this.$refs.dialog)
      this.$refs.dialog.addEventListener('hidden.bs.modal', () => {
        this.visible = false
        this.$emit('input', this.visible)
      })
    },

    watch: {
      value: {
        handler: function() {
          this.showHide(this.value)
        },
        immediate: true
      },
    },

    methods: {
      requestContact: function(e) {
        let hasEmail = false, hasNumber = false

        let emails = this.contactEmailOrPhone.match(/([a-zA-Z0-9._+-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi)
        hasEmail = !( emails===null || emails.length===0 )

        let numbers = new RegExp("\\+?\\(?\\d*\\)? ?\\(?\\d+\\)?\\d*([\\s./-]?\\d{2,})+","g").exec(this.contactEmailOrPhone)
        hasNumber = false
        if ( numbers!=null ) {
          for ( let i=0; i<numbers.length; i++ ) {
            let test = numbers[i].replace('-', '').replace(' ','')
            if ( test.length>=10 && test.length<=11 ) {
              hasNumber = true
              break
            }
          }
        }

        if ( !hasEmail && !hasNumber ) {
          this.badContact = true
        } else {
          lib.getContacted(
            jslib.config.endpoints.contact[lib.env()],
            {
              ProducerCode: this.Config.Producer.Code,
              ContactName: this.contactName,
              ContactEmailOrPhone: this.contactEmailOrPhone,
            },
            () => {
              this.contactRequested = true
            },
            () => {
              this.contactRequested = true
            },
            () => {
              this.contactRequested = true
            }
          )

        }

        if ( e!=undefined ) {
          e.preventDefault()
        }
      },

      showHide: function(status) {
        this.$emit('input', this.visible = status)
        if ( this.modal ) {
          if (this.visible) {
            this.modal.show()
            this.onshow()
          } else {
            this.modal.hide()
          }
        }
      },

      closeAction: function() {
        if ( this.contactRequested || (this.contactName.trim()==='' && this.contactEmailOrPhone.trim()==='') ) {
          this.visible = false
        } else {
          this.requestContact()
        }
      },

      onshow: function() {
        this.contactName = ''
        this.contactEmailOrPhone = ''
        this.badContact = false
        this.contactRequested = false

        lib.getTextFile(this.$t(this.body), (response) => {
          let snippet = document.createElement('div')
          snippet.innerHTML = response.data
          Object.keys(this.ArgumentObject).forEach(item => {
            let match = snippet.getElementsByClassName(`${item}-optional`)
            if ( !this.ArgumentObject[item] ) {
              for ( let item of match ) {
                item.remove()
              }
            }
          })
          this.content = lib.interpolate(snippet.innerHTML, this.ArgumentObject)
        })
      },
    },

    computed: {
      closeText: function() {
        if ( !this.contactRequested && (this.contactName.trim()!='' || this.contactEmailOrPhone.trim()!='') ) {
          return this.$t("rtf-dialog.contact-request")
        } else {
          return this.$t(this.close)
        }
      },

      ...mapFields([
        'Config',
      ]),

    }
  }
</script>
