<template>
  <div class="frame-container-custom">
    <div class="frame-body">
      <div class="container-fluid header-custom">
        <div class="row justify-content-center align-items-center header-row-custom">
          <div class="col text-start p-0">
            <a v-if="clickableLogo" :href="this.Config.Producer.Website" target="_blank"><img v-if="logoImage!=''" :src="logoImage" class="logo-custom"></a>
            <img v-else :src="logoImage" class="logo-custom">
          </div>
          <div class="col text-end p-0">
            <button class="btn btn-outline-info btn-sm language-button-custom" type="button" @click="toggleLanguage"> <font-awesome-icon icon="globe-americas" /> {{$t('app.toggle-language')}}</button>
            <button class="btn btn-outline-info btn-sm language-button-custom" type="button" @click="showHelp"> <font-awesome-icon icon="question-circle" style="color: #01b67a" /> </button>
          </div>
        </div>
      </div>

      <div class="container-fluid photo-container-custom">
        <div class="row justify-content-center">
          <div class="col">
            <div class="back-arrow-custom" v-if="showBackArrow">
              <font-awesome-icon icon="chevron-left" size="3x" @click="back" />
            </div>
          </div>
          <div class="col text-center">
            <img v-if="operatorImage!=''" :src="operatorImage" @click="showHelp" class="photo-custom-block animate__animated animate__zoomIn" />
          </div>
          <div class="col">
          </div>
        </div>
      </div>

      <router-view v-if="status==='ok'" />

      <div class="container-fluid" v-if="['requesting', 'error'].includes(status)" style="padding-top: 40px">
        <div class="row justify-content-center">
          <div class="col text-center">
            <button :disabled="status=='requesting'" class="btn btn-primary cool-primary-gradient" @click="refreshAgency">
              {{status==="error" ? $t('app.retry') : $t('app.requesting')}}
              <spinner :processing="status==='requesting'" color='#3c3' size="16px" />
            </button>
          </div>
        </div>
      </div>
    </div>

    <app-footer/>

    <rtf-dialog v-if="screenHelpAvailable"
      v-model="screenHelpDialog"
      :title="screenHelpDialogTitle"
      :body="screenHelpDialogBody"
      :close="screenHelpDialogClose"
      :contact="true"
      :argument-object="{ phone: Config.Producer.Phone, agency: Config.Producer.Agency, website: Config.Producer.Website }" />

    <alert-dialog v-model="unsupportedBrowserDialog" :message="errorMessage" />

    <alert-dialog v-model="systemDownDialog" :message="errorMessage" />

  </div>
</template>

<style scoped lang="scss">
  .header-custom {
    border-bottom: 1px solid #ddf;
  }

  .header-row-custom {
    min-height: 45px;
  }

  .photo-container-custom {
    max-height: 50px;
  }

  .frame-container-custom {
    padding-bottom: 60px;
    min-height: 100%;
    position: relative;
  }

  .logo-custom {
    position: relative;
    left: 0px;
    margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 2px;
    width: 35vw;
    max-width: 200px;
  }

  .language-button-custom {
    border-color: #a0a0a0;
    float: right;
    margin-right: 4px;
    margin-top: 3px;
    margin-bottom: 2px;
  }

  .language-button-custom:hover {
    color: white;
  }

  @media (max-width: 330px) {
    .language-button-custom {
      font-size: 3.5vw;
    }
  }

  .back-arrow-custom {
    position: relative;
    top: 3px;
    left: -6px;
    color: #ccc;
    z-index: 100;
  }

  .back-arrow-custom:hover {
    color: #5a4;
  }

  .photo-custom-block {
    border-radius: 50%;
    border: 1px solid #ddf;
    padding: 0px;
    width: 80px;
    height: 80px;
    position: relative;
    top: -40px;
  }

  .step {
    font-size: 11px;
    text-align: center;
    color: #666;
    cursor: default;
    margin: 0 2px;
    padding: 10px 10px 10px 30px;
    min-width: 50px;
    float: left;
    position: relative;
    background-color: #08b9eb;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: background-color 1s ease;
    max-height: 34px;
  }

  .step:after,
  .step:before {
    content: " ";
    position: absolute;
    top: 0;
    right: -17px;
    width: 0;
    height: 0;
    border-top: 17px solid transparent;
    border-bottom: 17px solid transparent;
    border-left: 17px solid #08b9eb;
    z-index: 2;
    transition: border-color 1s ease;
  }

  .step:before {
    right: auto;
    left: 0;
    border-left: 17px solid white;
    z-index: 0;
  }

  .step:first-child:before {
    border: none;
  }

  .step:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .step span {
    position: relative;
  }

  .step span:before {
    opacity: 0;
    content: "✔";
    position: absolute;
    top: -2px;
    left: -20px;
  }

  .step.done span:before {
    opacity: 1;
    -webkit-transition: opacity 0.3s ease 0.5s;
    -moz-transition: opacity 0.3s ease 0.5s;
    -ms-transition: opacity 0.3s ease 0.5s;
    transition: opacity 0.3s ease 0.5s;
  }

  .step.current {
    color: #fff;
    background-color: #23468c;
  }

  .step.current:after {
    border-left: 17px solid #23468c;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
</style>

<script>
  import lib from '@/lib'
  import router from '@/router'
  import config from '@/Config.js'

  import jslib from '@mexipassit/jslib'
  import { mapFields } from 'vuex-map-fields'
  import 'url-search-params-polyfill'
  import RtfDialog from '@/components/RtfDialog'
  import Spinner from '@/components/Spinner'
  import AppFooter from '@/components/AppFooter'
  import AlertDialog from '@/components/AlertDialog'
  import Bowser from 'bowser'

  export default {
    components: {
      RtfDialog,
      Spinner,
      AppFooter,
      AlertDialog,
    },

    props: ['DataLayer'],

    data: function() {
      return {
        screenHelpDialog: false,
        status: '',
        operatorImage: '',
        logoImage: '',
        clickableLogo: false,
        unsupportedBrowserDialog: false,
        systemDownDialog: false,
        errorMessage: '',
      }
    },

    created: function() {
      // copy the whole config for other screens to access its data without reloading it
      this.Config = config

      for (let event of ['click', 'keyup']) {
        document.addEventListener(event, () => {
          this.InactiveSeconds = 0
        })
      }

      setInterval(() => {
        if ( this.InactiveSeconds===this.Config.Timeout-1 ) {
          this.InactiveSeconds = 0
          this.PaymentCreditCard = ''
          this.PaymentCVC = ''
        } else {
          this.InactiveSeconds = this.InactiveSeconds+1
        }
      }, this.Config.Defaults.IdleTimeout)

      // config loading
      this.Program = this.Config.Defaults.Program
      this.Vehicles = this.Config.Vehicles
      this.States = this.Config.States
      this.DaysOfCoverage = this.Config.DaysOfCoverage
      this.VehicleValues = this.Config.VehicleValues
      this.LiabilityLimits = this.Config.LiabilityLimits
      let vehicleYears = []
      for ( let year = new Date().getFullYear() + 1; year >= new Date().getFullYear() - 25; year-- ) {
        vehicleYears.push({ Value: year, Display: year })
      }
      this.VehicleYears = vehicleYears
      this.LimitTable = this.Config.LimitTable
      this.SelectedDaysOfCoverage = this.Config.Defaults.DaysOfCoverage
      this.SelectedVehicleValue = this.Config.Defaults.VehicleValue
      this.SelectedLiabilityLimit = this.Config.Defaults.LiabilityLimit
      this.SelectedDriverInfoCountry = this.Config.Defaults.DriverInfoCountry
      this.SelectedDriverInfoState = this.Config.Defaults.DriverInfoState
      this.TermsAndConditionsAccepted = this.Config.Defaults.TermsAndConditionsAccepted
      this.SelectedVehicleCountry = this.Config.Defaults.VehicleCountry
      this.LienholderSelection = this.Config.Defaults.LienholderSelection
      this.LienholderCountry = this.Config.Defaults.LienholderCountry
      this.RentalVehicle = this.Config.Defaults.RentalVehicle
      this.FullCoverageInUSA = this.Config.Defaults.FullCoverageInUSA

      let params = new URLSearchParams(window.location.search.substring(1))

      if ( !lib.isProd() && params.get('Defaults') != undefined ) {
        this.SelectedDaysOfCoverage = this.Config.Defaults.Development.DaysOfCoverage
        this.SelectedVehicleValue = this.Config.Defaults.Development.VehicleValue
        this.AllDriversInAgeRange = this.Config.Defaults.Development.AllDriversInAgeRange
        this.VehicleVin = this.Config.Defaults.Development.VehicleVin
        this.SelectedVehicleState = this.Config.Defaults.Development.VehicleState
        this.LicensePlate = this.Config.Defaults.Development.LicensePlate
        this.ApplicantFirstName = this.Config.Defaults.Development.ApplicantFirstName
        this.ApplicantLastName = this.Config.Defaults.Development.ApplicantLastName
        this.ApplicantDateOfBirth = this.Config.Defaults.Development.ApplicantDateOfBirth
        this.ApplicantAddress = this.Config.Defaults.Development.ApplicantAddress
        this.ApplicantUnit = this.Config.Defaults.Development.ApplicantUnit
        this.TermsAndConditionsAccepted = this.Config.Defaults.Development.TermsAndConditionsAccepted
        this.DriversLicense = this.Config.Defaults.Development.DriversLicense
        this.SelectedDriverInfoCountry = this.Config.Defaults.Development.DriverInfoCountry
        this.SelectedDriverInfoState = this.Config.Defaults.Development.DriverInfoState
        this.ApplicantEmail = this.Config.Defaults.Development.ApplicantEmail
        this.ApplicantPhone = this.Config.Defaults.Development.ApplicantPhone
        this.PaymentCreditCard = this.Config.Defaults.Development.PaymentCreditCard
        this.PaymentCVC = this.Config.Defaults.Development.PaymentCVC
        this.PaymentMM = this.Config.Defaults.Development.PaymentMM
        this.PaymentYY = this.Config.Defaults.Development.PaymentYY
        this.PaymentZip = this.Config.Defaults.Development.PaymentZip
        this.LienholderName = this.Config.Defaults.Development.LienholderName
        this.LienholderCountry = this.Config.Defaults.Development.LienholderCountry
        this.LienholderAddress1 = this.Config.Defaults.Development.LienholderAddress1
        this.LienholderAddress2 = this.Config.Defaults.Development.LienholderAddress2
        this.LienholderCity = this.Config.Defaults.Development.LienholderCity
        this.LienholderState = this.Config.Defaults.Development.LienholderState
        this.LienholderPostalCode = this.Config.Defaults.Development.LienholderPostalCode

        this.CoverageAndPaymentStartDate = (new Date().getMonth()+1) + '/' + (new Date().getDate()) + '/' + (new Date().getFullYear())
      }

    },

    mounted: function() {
      if ( this.DataLayer!=false ) {
        lib.gaDataLayer({'event': 'optimize.CoverageAndLimitsReview', 'locale': this.$i18n.locale})
      }

      // On app load, check the initial url as provided, if it has no producer code (initial url route) or, it has it with an attempt
      // of an additional route to jump to, then redirct to the default url
      let parts = window.location.pathname.split('/').filter( i => i )
      if ( parts.length==0 || ( parts.length>1 && parts[1].toLowerCase()!='stamp' && parts[1].toLowerCase()!='es') ) {
        if ( !lib.isProd() && localStorage && localStorage.getItem('Producer.Code')!=this.Config.Defaults.Development.ProducerCode ) {
          localStorage.setItem('Producer.Code', this.Config.Defaults.Development.ProducerCode)
        }
        router.push({ path: '/' + lib.GetSetProducer( lib.isProd() ? this.Config.Producer.Code : this.Config.Defaults.Development.ProducerCode ) })
      }

      let _self = this
      this.$router.beforeEach((to, from, next) => {
        next(!_self.Processing)
      })

      var browser = Bowser.getParser(window.navigator.userAgent).getBrowserName()
      if (browser == 'Internet Explorer') {
        this.unsupportedBrowserDialog = true
        this.errorMessage = this.$t('unsupported-browser-dialog')
      }

      // uncomment to activate System Down Dialog
      //this.systemDownDialog = true
      //this.errorMessage = this.$t('system-down-dialog')
    },

    updated: function() {
      lib.gaDataLayer({'event': 'optimize.LocaleChange', 'locale': this.$i18n.locale})
      if ( this.PaymentPolicyNumber!='' && !this.$route.path.endsWith('/confirmation') ) {
        router.push({ name: 'confirmation' })
      } else if ( this.PaymentPolicyNumber==='' && this.$route.path.endsWith('/confirmation')) {
        router.push({ name: 'quote1' })
      }

      lib.setupTooltips()
    },

    watch: {
      $route() {

        // allow /es and /stamp in the root path segment as special conditions, mimicking the Spanish and Stamp views
        if ( this.$route.params ) {
          if ( this.$route.params.producer.length==7 ) {
            localStorage.setItem('Producer.Code', this.Config.Producer.Code = this.$route.params.producer)
            this.refreshAgency()
          } else if ( this.$route.params.producer && this.$route.params.producer.length != 7 ) {
            window.location.href = 'producer-not-found.html'
          }
        }

        lib.gaDataLayer({'event': 'optimize.activate', 'locale': this.$i18n.locale})
      }
    },

    methods: {
      refreshAgency: function() {

        if ( !this.Config.Producer.Agency ) {

          this.Config.Producer.Agency = ''
          this.Config.Producer.Phone = ''
          this.Config.Producer.Website = ''
          this.status = 'requesting'

          lib.agencyInfo(
            jslib.config.endpoints.buyAgencyInfo[lib.env()],
            {
              ProducerCode: this.Config.Producer.Code
            },
            (response) => {
              if ( response.data.success===true ) {
                this.Config.Producer.Agency = response.data.agency
                this.Config.Producer.Phone = response.data.phone
                this.operatorImage = response.data.operator_image=='false' ? '/img/photo.jpg' : response.data.operator_image
                let defaultLogo = '/img/mexipass-logo-large.png'
                this.logoImage = response.data.logo_image=='false' ? defaultLogo : response.data.logo_image
                this.HasProducerLogo = this.logoImage != defaultLogo
                response.data.website = (''+(response.data.website || '')).trim().toLowerCase()
                if ( response.data.website && !response.data.website.startsWith('http://') && !response.data.website.startsWith('https://') ) {
                  response.data.website = 'https://' + response.data.website
                }
                this.clickableLogo = response.data.logo_image=='false' || !response.data.website ? false : true
                this.Config.Producer.Website = response.data.website
                this.status = 'ok'
              } else {
                localStorage.removeItem('Producer.Code')
                window.location.href = 'producer-not-found.html'
              }
            },
            () => {
              this.status = 'error'
            }
          )
        }
      },

      back: function() {
        lib.previousRoute(this.$router, this.SelectedVehicleValue==='Liability only', this.Program===this.Config.Programs.stamp)
      },

      toggleLanguage: function() {
        this.$i18n.locale = this.$i18n.locale=='en' ? 'es' : 'en'
      },

      showHelp: function() {
        if ( this.screenHelpAvailable && this.status==='ok' ) {
          this.screenHelpDialog = true
        }
      }
    },

    computed: {
      showBackArrow() {
        return this.$route.name != 'quote1' && this.$route.name != 'confirmation' && this.status != 'requesting'
      },

      screenHelpAvailable: function() {
        let path = this.$router.options.routes.find(_ => _.path = this.$route.path).name + '.screen-help.title'
        return this.$t(path) != path
      },

      screenHelpDialogTitle: function() {
        return this.$t( this.$router.options.routes.find(_ => _.path = this.$route.path).name + '.screen-help.title' )
      },

      screenHelpDialogBody: function() {
        return this.$t( this.$router.options.routes.find(_ => _.path = this.$route.path).name + '.screen-help.body' )
      },

      screenHelpDialogClose: function() {
        return this.$t( this.$router.options.routes.find(_ => _.path = this.$route.path).name + '.screen-help.close' )
      },

      ...mapFields([
        'HasProducerLogo',
        'PaymentMM',
        'PaymentYY',
        'PaymentZip',
        'LicensePlate',
        'ApplicantFirstName',
        'ApplicantLastName',
        'ApplicantDateOfBirth',
        'ApplicantAddress',
        'ApplicantUnit',
        'TermsAndConditionsAccepted',
        'DriversLicense',
        'ApplicantEmail',
        'ApplicantPhone',
        'CoverageAndPaymentStartDate',
        'SelectedVehicleState',
        'VehicleVin',
        'AllDriversInAgeRange',
        'FullCoverageInUSA',
        'RentalVehicle',
        'DaysOfCoverage',
        'VehicleValues',
        'LiabilityLimits',
        'VehicleYears',
        'LimitTable',
        'SelectedDaysOfCoverage',
        'SelectedVehicleValue',
        'SelectedLiabilityLimit',
        'SelectedDriverInfoCountry',
        'SelectedDriverInfoState',
        'SelectedVehicleCountry',
        'States',
        'Vehicles',
        'PaymentCreditCard',
        'PaymentCVC',
        'Config',
        'InactiveSeconds',
        'Program',
        'PaymentPolicyNumber',
        'LienholderSelection',
        'LienholderName',
        'LienholderCountry',
        'LienholderAddress1',
        'LienholderAddress2',
        'LienholderCity',
        'LienholderState',
        'LienholderPostalCode',
        'InsuranceCompany',
        'Processing',
      ]),

    },
  }
</script>
