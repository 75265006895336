import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckCircle, faGlobeAmericas, faCreditCard, faPlus, faMinus, faChevronLeft, faArrowAltCircleRight, faFilePdf, faQuestionCircle }
  from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import '@/styles.scss'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

library.add(faCheckCircle, faGlobeAmericas, faCreditCard, faPlus, faMinus, faChevronLeft, faArrowAltCircleRight, faFilePdf, faQuestionCircle)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

let messages = require('@/i18n.json')
let i18n = new VueI18n({ locale: 'en', messages, fallbackLocale: 'en', silentTranslationWarn: true })

Vue.prototype.$tooltips = []

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
