export default {
  "Programs": {
    "masplus": "mas-plus",
    "stamp": "stamp",
    "mxpatriate": "mxp",
    "Rater": {
      "mas-plus": "Special Plus",
      "mxp": "MxPatriate",
      "stamp": "STAMP",
    }
  },
  "Carriers": {
    "aig" : {
      "display": "AIG",
      "code": "aig",
    },
    "chubb": {
      "display": "Chubb",
      "code": "chubb",
    },
    "ace": {
      "display": "Chubb",
      "code": "chubb",
    }
  },
  "Defaults": {
    "IdleTimeout": 1000,
    "Program": "mas-plus",
    "DaysOfCoverage": "",
    "VehicleValue": "",
    "LiabilityLimit": "$300,000",
    "VehicleCountry": "USA",
    "LienholderCountry": "USA",
    "DriverInfoCountry": "",
    "DriverInfoState": "",
    "TermsAndConditionsAccepted": false,
    "LienholderSelection": false,
    "RentalVehicle": false,
    "FullCoverageInUSA": false,
    "Development": {
      "ProducerCode": "BROKER1",
      "DaysOfCoverage": "1",
      "VehicleValue": "Liability only",
      "AllDriversInAgeRange": true,
      "VehicleVin": "1FTRX17LXYKA83591",
      "VehicleState": "California",
      "LicensePlate": "1a2b3c4e",
      "ApplicantFirstName": "mickey",
      "ApplicantLastName": "mouse",
      "ApplicantDateOfBirth": "11-22-1973",
      "ApplicantAddress": "19608 Sherman Way, Reseda, CA 91335, USA",
      "ApplicantUnit": "253",
      "TermsAndConditionsAccepted": true,
      "DriversLicense": "B9826333",
      "DriverInfoCountry": "USA",
      "DriverInfoState": "California",
      "ApplicantEmail": "andrea@mexipass.com",
      "ApplicantPhone": "(818) 723-6833",
      "PaymentCreditCard": "4111111111111111",
      "PaymentCVC": "150",
      "PaymentMM": "10",
      "PaymentYY": "25",
      "PaymentZip": "12345",
      "LienholderName": "bank of america",
      "LienholderCountry": "USA",
      "LienholderAddress1": "880 E Colorado Blvd",
      "LienholderAddress2": "123",
      "LienholderCity": "Pasadena",
      "LienholderState": "California",
      "LienholderPostalCode": "91333",
    }
  },
  "Timeout": 300,
  "Producer" : {
    "Code": "MEXI001",
    "Agency": "",
    "Phone": "",
    "Website": "",
  },
  "RequestTimeoutMilliseconds": 200,
  "LiabilityLimits": [
    { "Value": "$300,000", "i18n": "liability-limits.300000" },
    { "Value": "$500,000", "i18n": "liability-limits.500000" },
    { "Value": "$1,000,000", "i18n": "liability-limits.1000000" },
  ],
  "VehicleValues": [
    { "Value": "Liability only", "i18n": "vehicle-values.0" },
    { "Value": "$1 to $5,000", "i18n": "vehicle-values.0_5k", "Max": "$5,000" },
    { "Value": "$5,001 to $10,000", "i18n": "vehicle-values.5_10k", "Max": "$10,000" },
    { "Value": "$10,001 to $15,000", "i18n": "vehicle-values.10_15k", "Max": "$15,000" },
    { "Value": "$15,001 to $20,000", "i18n": "vehicle-values.15_20k", "Max": "$20,000" },
    { "Value": "$20,001 to $25,000", "i18n": "vehicle-values.20_25k", "Max": "$25,000" },
    { "Value": "$25,001 to $30,000", "i18n": "vehicle-values.25_30k", "Max": "$30,000" },
    { "Value": "$30,001 to $35,000", "i18n": "vehicle-values.30_35k", "Max": "$35,000" },
    { "Value": "$35,001 to $40,000", "i18n": "vehicle-values.35_40k", "Max": "$40,000" },
    { "Value": "$40,001 to $50,000", "i18n": "vehicle-values.40_50k", "Max": "$50,000" },
    { "Value": "$50,001 to $60,000", "i18n": "vehicle-values.50_60k", "Max": "$60,000" },
    { "Value": "$60,001 to $80,000", "i18n": "vehicle-values.60_80k", "Max": "$80,000" },
    { "Value": "$80,001 to $100,000", "i18n": "vehicle-values.80_100k", "Max": "$100,000" },
  ],
  "DaysOfCoverage": [
    { "Days": "1", "Rater": "1", "i18n": "1" },
    { "Days": "2", "Rater": "2", "i18n": "2" },
    { "Days": "3", "Rater": "3", "i18n": "3" },
    { "Days": "4", "Rater": "4", "i18n": "4" },
    { "Days": "5", "Rater": "5", "i18n": "5" },
    { "Days": "6", "Rater": "6", "i18n": "6" },
    { "Days": "7", "Rater": "7", "i18n": "7" },
    { "Days": "8", "Rater": "8", "i18n": "8" },
    { "Days": "9", "Rater": "9", "i18n": "9" },
    { "Days": "10", "Rater": "10", "i18n": "10" },
    { "Days": "11", "Rater": "11", "i18n": "11" },
    { "Days": "12", "Rater": "12", "i18n": "12" },
    { "Days": "13", "Rater": "13", "i18n": "13" },
    { "Days": "14", "Rater": "14", "i18n": "14" },
    { "Days": "15", "Rater": "15", "i18n": "15" },
    { "Days": "16", "Rater": "16", "i18n": "16" },
    { "Days": "17", "Rater": "17", "i18n": "17" },
    { "Days": "18", "Rater": "18", "i18n": "18" },
    { "Days": "19", "Rater": "19", "i18n": "19" },
    { "Days": "20", "Rater": "20", "i18n": "20" },
    { "Days": "30", "Rater": "30", "i18n": "30" },
    { "Days": "60", "Rater": "60", "i18n": "60" },
    { "Days": "90", "Rater": "90", "i18n": "90" },
    { "Days": "183", "Rater": "6 Months", "i18n": "days-of-coverage.183" },
    { "Days": "365", "Rater": "Annual", "i18n": "days-of-coverage.365" },
  ],
  "LimitTable": {
    "mas-plus": [
      {
        "Name": "limit-table.limit-table-policy-coverage",
        "Items": [
          { "Coverage": "mas-plus-limit-table-0.coverage-0", "Limits": "mas-plus-limit-table-0.limits-0" },
          { "Coverage": "mas-plus-limit-table-0.coverage-1", "Limits": "mas-plus-limit-table-0.limits-1", "ForMotorcycleOnly": "limit-table.not-covered" },
          { "Coverage": "mas-plus-limit-table-0.coverage-2", "Limits": "mas-plus-limit-table-0.limits-2" },
          { "Coverage": "mas-plus-limit-table-0.coverage-3", "Limits": "mas-plus-limit-table-0.limits-3" },
          { "Coverage": "mas-plus-limit-table-0.coverage-4", "Limits": "mas-plus-limit-table-0.limits-4" },
        ]
      },
      {
        "Name": "limit-table.limit-table-vehicle-coverage",
        "Items": [
          { "Coverage": "mas-plus-limit-table-1.coverage-0", "Limits": "mas-plus-limit-table-1.limits-0", "ForLiabilityOnly": "limit-table.not-covered" },
          { "Coverage": "mas-plus-limit-table-1.coverage-1", "Limits": "mas-plus-limit-table-1.limits-1", "ForLiabilityOnly": "limit-table.not-applicable" },
          { "Coverage": "mas-plus-limit-table-1.coverage-2", "Limits": "mas-plus-limit-table-1.limits-2", "ForLiabilityOnly": "limit-table.not-applicable" },
          { "Coverage": "mas-plus-limit-table-1.coverage-3", "Limits": "mas-plus-limit-table-1.limits-3", "ForLiabilityOnly": "limit-table.not-covered" },
          { "Coverage": "mas-plus-limit-table-1.coverage-4", "Limits": "mas-plus-limit-table-1.limits-4", "ForLiabilityOnly": "limit-table.not-applicable" },
        ]
      },
      {
        "Name": "limit-table.limit-table-travel-protection-and-benefits",
        "Items": [
          { "Coverage": "mas-plus-limit-table-2.coverage-0", "Limits": "mas-plus-limit-table-2.limits-0" },
          { "Coverage": "mas-plus-limit-table-2.coverage-1", "Limits": "mas-plus-limit-table-2.limits-1" },
          { "Coverage": "mas-plus-limit-table-2.coverage-2", "Limits": "mas-plus-limit-table-2.limits-2" },
          { "Coverage": "mas-plus-limit-table-2.coverage-3", "Limits": "mas-plus-limit-table-2.limits-3" },
          { "Coverage": "mas-plus-limit-table-2.coverage-4", "Limits": "mas-plus-limit-table-2.limits-4" },
          { "Coverage": "mas-plus-limit-table-2.coverage-5", "Limits": "mas-plus-limit-table-2.limits-5" },
          { "Coverage": "mas-plus-limit-table-2.coverage-6", "Limits": "mas-plus-limit-table-2.limits-6" },
          { "Coverage": "mas-plus-limit-table-2.coverage-7", "Limits": "mas-plus-limit-table-2.limits-7" },
          { "Coverage": "mas-plus-limit-table-2.coverage-8", "Limits": "mas-plus-limit-table-2.limits-8" },
          { "Coverage": "mas-plus-limit-table-2.coverage-9", "Limits": "mas-plus-limit-table-2.limits-9" },
        ]
      }
    ],
    "mxp": [
      {
        "Name": "limit-table.limit-table-policy-coverage",
        "Items": [
          { "Coverage": "mxp-limit-table-0.coverage-0", "Limits": "mxp-limit-table-0.limits-0" },
          { "Coverage": "mxp-limit-table-0.coverage-1", "Limits": "mxp-limit-table-0.limits-1", "ForMotorcycleOnly": "limit-table.not-covered" },
          { "Coverage": "mxp-limit-table-0.coverage-2", "Limits": "mxp-limit-table-0.limits-2" },
          { "Coverage": "mxp-limit-table-0.coverage-3", "Limits": "mxp-limit-table-0.limits-3" },
          { "Coverage": "mxp-limit-table-0.coverage-4", "Limits": "mxp-limit-table-0.limits-4" },
        ]
      },
      {
        "Name": "limit-table.limit-table-vehicle-coverage",
        "Items": [
          { "Coverage": "mxp-limit-table-1.coverage-0", "Limits": "mxp-limit-table-1.limits-0", "ForLiabilityOnly": "limit-table.not-covered" },
          { "Coverage": "mxp-limit-table-1.coverage-1", "Limits": "mxp-limit-table-1.limits-1", "ForLiabilityOnly": "limit-table.not-applicable" },
          { "Coverage": "mxp-limit-table-1.coverage-2", "Limits": "mxp-limit-table-1.limits-2", "ForLiabilityOnly": "limit-table.not-applicable" },
          { "Coverage": "mxp-limit-table-1.coverage-3", "Limits": "mxp-limit-table-1.limits-3", "ForLiabilityOnly": "limit-table.not-covered" },
          { "Coverage": "mxp-limit-table-1.coverage-4", "Limits": "mxp-limit-table-1.limits-4", "ForLiabilityOnly": "limit-table.not-applicable" },
        ]
      },
      {
        "Name": "limit-table.limit-table-travel-protection-and-benefits",
        "Items": [
          { "Coverage": "mxp-limit-table-2.coverage-0", "Limits": "mxp-limit-table-2.limits-0" },
          { "Coverage": "mxp-limit-table-2.coverage-1", "Limits": "mxp-limit-table-2.limits-1" },
          { "Coverage": "mxp-limit-table-2.coverage-2", "Limits": "mxp-limit-table-2.limits-2" },
          { "Coverage": "mxp-limit-table-2.coverage-3", "Limits": "mxp-limit-table-2.limits-3" },
          { "Coverage": "mxp-limit-table-2.coverage-4", "Limits": "mxp-limit-table-2.limits-4" },
          { "Coverage": "mxp-limit-table-2.coverage-5", "Limits": "mxp-limit-table-2.limits-5" },
          { "Coverage": "mxp-limit-table-2.coverage-6", "Limits": "mxp-limit-table-2.limits-6" },
          { "Coverage": "mxp-limit-table-2.coverage-7", "Limits": "mxp-limit-table-2.limits-7" },
          { "Coverage": "mxp-limit-table-2.coverage-8", "Limits": "mxp-limit-table-2.limits-8" },
          { "Coverage": "mxp-limit-table-2.coverage-9", "Limits": "mxp-limit-table-2.limits-9" },
        ]
      }
    ],
    "stamp": [
      {
        "Name": "limit-table.limit-table-policy-coverage",
        "Items": [
          { "Coverage": "stamp-limit-table-0.coverage-0", "Limits": "stamp-limit-table-0.limits-0" },
          { "Coverage": "stamp-limit-table-0.coverage-1", "Limits": "stamp-limit-table-0.limits-1", "ForMotorcycleOnly": "limit-table.not-covered" },
          { "Coverage": "stamp-limit-table-0.coverage-2", "Limits": "stamp-limit-table-0.limits-2" },
          { "Coverage": "stamp-limit-table-0.coverage-3", "Limits": "stamp-limit-table-0.limits-3" },
          { "Coverage": "stamp-limit-table-0.coverage-4", "Limits": "stamp-limit-table-0.limits-4" },
        ]
      },
      {
        "Name": "limit-table.limit-table-vehicle-coverage",
        "Items": [
          { "Coverage": "stamp-limit-table-1.coverage-0", "Limits": "stamp-limit-table-1.limits-0", "ForLiabilityOnly": "limit-table.not-covered" },
          { "Coverage": "stamp-limit-table-1.coverage-1", "Limits": "stamp-limit-table-1.limits-1", "ForLiabilityOnly": "limit-table.not-applicable" },
          { "Coverage": "stamp-limit-table-1.coverage-2", "Limits": "stamp-limit-table-1.limits-2", "ForLiabilityOnly": "limit-table.not-applicable" },
          { "Coverage": "stamp-limit-table-1.coverage-3", "Limits": "stamp-limit-table-1.limits-3", "ForLiabilityOnly": "limit-table.not-covered" },
          { "Coverage": "stamp-limit-table-1.coverage-4", "Limits": "stamp-limit-table-1.limits-4", "ForLiabilityOnly": "limit-table.not-applicable" },
        ]
      },
      {
        "Name": "limit-table.limit-table-travel-protection-and-benefits",
        "Items": [
          { "Coverage": "stamp-limit-table-2.coverage-0", "Limits": "stamp-limit-table-2.limits-0" },
        ]
      }
    ]
  },
  "States": [
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Alabama", "statei18n" : "country.usa.alabama", "Abbreviation": "AL" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Alaska", "statei18n" : "country.usa.alaska", "Abbreviation": "AK" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Arizona", "statei18n" : "country.usa.arizona", "Abbreviation": "AZ" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Arkansas", "statei18n" : "country.usa.arkansas", "Abbreviation": "AR" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "California", "statei18n" : "country.usa.california", "Abbreviation": "CA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Colorado", "statei18n" : "country.usa.colorado", "Abbreviation": "CO" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Connecticut", "statei18n" : "country.usa.connecticut", "Abbreviation": "CT" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Delaware", "statei18n" : "country.usa.delaware", "Abbreviation": "DE" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "District Of Columbia", "statei18n" : "country.usa.district-of-columbia", "Abbreviation": "DC" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Florida", "statei18n" : "country.usa.florida", "Abbreviation": "FL" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Georgia", "statei18n" : "country.usa.georgia", "Abbreviation": "GA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Hawaii", "statei18n" : "country.usa.hawaii", "Abbreviation": "HI" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Idaho", "statei18n" : "country.usa.idaho", "Abbreviation": "ID" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Illinois", "statei18n" : "country.usa.illinois", "Abbreviation": "IL" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Indiana", "statei18n" : "country.usa.indiana", "Abbreviation": "IN" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Iowa", "statei18n" : "country.usa.iowa", "Abbreviation": "IA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Kansas", "statei18n" : "country.usa.kansas", "Abbreviation": "KS" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Kentucky", "statei18n" : "country.usa.kentucky", "Abbreviation": "KY" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Louisiana", "statei18n" : "country.usa.louisiana", "Abbreviation": "LA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Maine", "statei18n" : "country.usa.maine", "Abbreviation": "ME" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Maryland", "statei18n" : "country.usa.maryland", "Abbreviation": "MD" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Massachusetts", "statei18n" : "country.usa.massachusetts", "Abbreviation": "MA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Michigan", "statei18n" : "country.usa.michigan", "Abbreviation": "MI" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Minnesota", "statei18n" : "country.usa.minnesota", "Abbreviation": "MN" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Mississippi", "statei18n" : "country.usa.mississippi", "Abbreviation": "MS" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Missouri", "statei18n" : "country.usa.missouri", "Abbreviation": "MO" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Montana", "statei18n" : "country.usa.montana", "Abbreviation": "MT" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Nebraska", "statei18n" : "country.usa.nebraska", "Abbreviation": "NE" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Nevada", "statei18n" : "country.usa.nevada", "Abbreviation": "NV" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "New Hampshire", "statei18n" : "country.usa.new-hampshire", "Abbreviation": "NH" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "New Jersey", "statei18n" : "country.usa.new-jersey", "Abbreviation": "NJ" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "New Mexico", "statei18n" : "country.usa.new-mexico", "Abbreviation": "NM" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "New York", "statei18n" : "country.usa.new-york", "Abbreviation": "NY" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "North Carolina", "statei18n" : "country.usa.north-carolina", "Abbreviation": "NC" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "North Dakota", "statei18n" : "country.usa.north-dakota", "Abbreviation": "ND" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Ohio", "statei18n" : "country.usa.ohio", "Abbreviation": "OH" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Oklahoma", "statei18n" : "country.usa.oklahoma", "Abbreviation": "OK" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Oregon", "statei18n" : "country.usa.oregon", "Abbreviation": "OR" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Pennsylvania", "statei18n" : "country.usa.pennsylvania", "Abbreviation": "PA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Rhode Island", "statei18n" : "country.usa.rhode-island", "Abbreviation": "RI" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "South Carolina", "statei18n" : "country.usa.south-carolina", "Abbreviation": "SC" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "South Dakota", "statei18n" : "country.usa.south-dakota", "Abbreviation": "SD" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Tennessee", "statei18n" : "country.usa.tennessee", "Abbreviation": "TN" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Texas", "statei18n" : "country.usa.texas", "Abbreviation": "TX" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Utah", "statei18n" : "country.usa.utah", "Abbreviation": "UT" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Vermont", "statei18n" : "country.usa.vermont", "Abbreviation": "VT" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Virginia", "statei18n" : "country.usa.virginia", "Abbreviation": "VA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Washington", "statei18n" : "country.usa.washington", "Abbreviation": "WA" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "West Virginia", "statei18n" : "country.usa.west-virginia", "Abbreviation": "WV" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Wisconsin", "statei18n" : "country.usa.wisconsin", "Abbreviation": "WI" },
    { "Country": "USA", "countryi18n": "country.usa.name", "State": "Wyoming", "statei18n" : "country.usa.wyoming", "Abbreviation": "WY" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Alberta", "statei18n" : "country.canada.alberta", "Abbreviation": "AB" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "British Columbia", "statei18n" : "country.canada.british-columbia", "Abbreviation": "BC" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Manitoba", "statei18n" : "country.canada.manitoba", "Abbreviation": "MB" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "New Brunswick", "statei18n" : "country.canada.new-brunswick", "Abbreviation": "NB" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Newfoundland and Labrador", "statei18n" : "country.canada.newfoundland-and-labrador", "Abbreviation": "NF" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Northwest Territories", "statei18n" : "country.canada.northwest-territories", "Abbreviation": "NT" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Nova Scotia", "statei18n" : "country.canada.nova-scotia", "Abbreviation": "NS" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Nunavut", "statei18n" : "country.canada.nunavut", "Abbreviation": "NU" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Ontario", "statei18n" : "country.canada.ontario", "Abbreviation": "ON" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Prince Edward Island", "statei18n" : "country.canada.prince-edward-island", "Abbreviation": "PE" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Quebec", "statei18n" : "country.canada.quebec", "Abbreviation": "QC" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Saskatchewan", "statei18n" : "country.canada.saskatchewan", "Abbreviation": "SK" },
    { "Country": "Canada", "countryi18n": "country.canada.name", "State": "Yukon", "statei18n" : "country.canada.yukon", "Abbreviation": "YT" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Aguascalientes", "statei18n" : "country.mexico.aguascalientes", "Abbreviation": "AG" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Baja California", "statei18n" : "country.mexico.baja-california", "Abbreviation": "BJ" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Baja California Sur", "statei18n" : "country.mexico.baja-california-sur", "Abbreviation": "BS" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Campeche", "statei18n" : "country.mexico.campeche", "Abbreviation": "CP" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Chihuahua", "statei18n" : "country.mexico.chihuahua", "Abbreviation": "CI" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Chiapas", "statei18n" : "country.mexico.chiapas", "Abbreviation": "CH" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Colima", "statei18n" : "country.mexico.colima", "Abbreviation": "CL" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Coahuila", "statei18n" : "country.mexico.coahuila", "Abbreviation": "CU" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Ciudad de México", "statei18n" : "country.mexico.ciudad-de-mexico", "Abbreviation": "DF" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Durango", "statei18n" : "country.mexico.durango", "Abbreviation": "DG" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Guerrero", "statei18n" : "country.mexico.guerrero", "Abbreviation": "GR" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Guanajuato", "statei18n" : "country.mexico.guanajuato", "Abbreviation": "GJ" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Hidalgo", "statei18n" : "country.mexico.hidalgo", "Abbreviation": "HG" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Jalisco", "statei18n" : "country.mexico.jalisco", "Abbreviation": "JA" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Michoacán", "statei18n" : "country.mexico.michoacan", "Abbreviation": "MH" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Morelos", "statei18n" : "country.mexico.morelos", "Abbreviation": "MR" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Estado de México", "statei18n" : "country.mexico.estado-de-mexico", "Abbreviation": "EM" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Nayarit", "statei18n" : "country.mexico.nayarit", "Abbreviation": "NA" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Nuevo León", "statei18n" : "country.mexico.nuevo-leon", "Abbreviation": "NL" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Oaxaca", "statei18n" : "country.mexico.oaxaca", "Abbreviation": "OA" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Puebla", "statei18n" : "country.mexico.puebla", "Abbreviation": "PU" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Quintana Roo", "statei18n" : "country.mexico.quintana-roo", "Abbreviation": "QR" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Querétaro", "statei18n" : "country.mexico.queretaro", "Abbreviation": "QA" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Sinaloa", "statei18n" : "country.mexico.sinaloa", "Abbreviation": "SI" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "San Luis Potosí", "statei18n" : "country.mexico.san-luis-potosi", "Abbreviation": "SL" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Sonora", "statei18n" : "country.mexico.sonora", "Abbreviation": "SO" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Tamaulipas", "statei18n" : "country.mexico.tamaulipas", "Abbreviation": "TM" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Tabasco", "statei18n" : "country.mexico.tabasco", "Abbreviation": "TA" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Tlaxcala", "statei18n" : "country.mexico.tlaxcala", "Abbreviation": "TL" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Veracruz", "statei18n" : "country.mexico.veracruz", "Abbreviation": "VZ" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Yucatán", "statei18n" : "country.mexico.yucatan", "Abbreviation": "YT" },
    { "Country": "Mexico", "countryi18n": "country.mexico.name", "State": "Zacatecas", "statei18n" : "country.mexico.zacatecas", "Abbreviation": "ZT" },
  ]
}
