import axios from 'axios'
import jslib from '@mexipassit/jslib'

export default {

  env: function() {
    let url = window.location.hostname
    if ( url.toLowerCase().includes('localhost') ) {
      return 'dev'
    } else if ( url.toLowerCase().includes('staging.') ) {
      return 'test'
    } else {
      return 'prod'
    }
  },

  isDev: function() {
    return this.env() == 'dev'
  },

  isTest: function() {
    return this.env() == 'test'
  },

  isProd: function() {
    return this.env() == 'prod'
  },

  toDate(s) {
    // Edge/IE fix, they add invisible bom characters which fail new Date() to parse
    s = s.replace(/[^ -~]/g,'')
    let d = new Date(s)
    if ( isNaN(d.getTime()) ) {
      d = new Date(s.replace(/-/g, '/'))
    }
    return d
  },

  birthDateFilledButIsGarbage(d) {
    let bits = d.split(new RegExp(['\\.', '\\-', '\\/'].join('|'), 'g'))

    if ( bits[0]!=undefined && bits[0]!='' && (+bits[0] < 1 || +bits[0] > 12) ) {
      return true
    }

    if ( bits[1]!=undefined && bits[1]!='' && (+bits[1] < 1 || +bits[1] > 31) ) {
      return true
    }

    if ( bits[2]!=undefined &&  bits[2].length===4 && (+bits[2] < ((new Date()).getFullYear() -120) || +bits[2] > (new Date()).getFullYear()) ) {
      return true
    }

    return false
  },

  birthDateSomewhatAlright(d) {
    let bits = d.split(new RegExp(['\\.', '\\-', '\\/'].join('|'), 'g'))

    if ( bits[0]===undefined || bits[0].trim()==='' || +bits[0] < 1 || +bits[0] > 12 ) {
      return false
    }

    if ( bits[1]===undefined || bits[1].trim()==='' || +bits[1] < 1 || +bits[1] > 31 ) {
      return false
    }

    if ( bits[2]===undefined || bits[2].trim()==='' || (+bits[2] < ((new Date()).getFullYear() -120)) || (+bits[2] > ((new Date()).getFullYear())) ) {
      return false
    }

    return true
  },

  googleMatchType(g, t) {
    if ( g && g.address_components ) {
      return g.address_components.find(item => item && item.types && item.types.find(item2 => item2==t))
    } else {
      return null
    }
  },

  googleMatchTypeLongDescriptionFirst(g, t) {
    let match = this.googleMatchType(g, t)
    return match ? match.long_name || match.short_name : ''
  },

  googleMatchTypeShortDescriptionFirst(g, t) {
    let match = this.googleMatchType(g, t)
    return match ? match.short_name || match.long_name : ''
  },

  googleToStNumber(g) {
    return this.googleMatchTypeLongDescriptionFirst(g, 'street_number')
  },

  googleToStreet(g) {
    return this.googleMatchTypeLongDescriptionFirst(g, 'route')
  },

  googleToCity(g) {
    return this.googleMatchTypeLongDescriptionFirst(g, 'locality') ||
      this.googleMatchTypeLongDescriptionFirst(g, 'sublocality') ||
      this.googleMatchTypeLongDescriptionFirst(g, 'neighborhood')
  },

  googleToState(g) {
    return this.googleMatchTypeLongDescriptionFirst(g, 'administrative_area_level_1')
  },

  googleToCountry(g) {
    let country = this.googleMatchTypeShortDescriptionFirst(g, 'country')
    if ( ['UNITED STATES', 'US', 'USA'].includes(country.trim().toUpperCase()) ) {
      return 'USA'
    } else if ( ['CANADA', 'CA'].includes(country.trim().toUpperCase()) ) {
      return 'Canada'
    } else if ( ['MEXICO'].includes(country.trim().toUpperCase()) ) {
      return 'Mexico'
    } else {
      return ''
    }
  },

  googleToZip(g) {
    return this.googleMatchTypeLongDescriptionFirst(g, 'postal_code')
  },

  googleFormatStreet(g) {
    let a = this.googleToStNumber(g)
    let b = this.googleToStreet(g)
    let format = (a + ' ' + b).trim()
    return format
  },

  normalizefromMDYtoYYYYMMDD(d) {
    let bits = d.split(new RegExp(['\\.', '\\-', '\\/'].join('|'), 'g'))

    return (+bits[2]).toString().padStart(4, '0') + '-' + (+bits[0]).toString().padStart(2, '0') + '-' + (+bits[1]).toString().padStart(2, '0')
  },

  paymentCreditCardCode(str) {
    let creditCardType = require('credit-card-type')

    let ccType = creditCardType(str)
    ccType = ccType.length===1 ? ccType[0].type : ''
    ccType = ccType=='visa' ? 'VI' : ccType=='mastercard' ? 'MC' : ccType=='discover' ? 'DI' : ccType=='american-express' ? 'AE' : ''

    return ccType
  },

  getTextFile(url, success) {
    axios({
      method: 'get',
      responseType: 'text',
      url
    }).then(response => {
      success && success(response)
    })
  },

  submitPayment(endpoint, args, onsuccess, onerror, onany, totalTime) {

    let thisRoundStart = new Date().getTime()
    totalTime = isNaN(totalTime) ? 0 : totalTime

    let payload = {
      appid: jslib.detectUrlMexiPolicyProId(),
      program: args.Program,
      liability_limit: args.SelectedLiabilityLimit,
      producer_code: args.ProducerCode,
      effective_date: this.normalizefromMDYtoYYYYMMDD( args.CoverageAndPaymentStartDate.replace(/[^ -~]/g,'') ), // Edge/IE fix, they add invisible bom characters which fail new Date() to parse
      app_record_id: args.RaterApplicationId.toString(),
      veh_record_id: args.RaterVehicleId.toString(),
      days_trip: args.SelectedDaysOfCoverage,
      vehicle_primarily_in_mexico: args.VehiclePrimarilyInMexico,
      applicant: {
        first_name: args.ApplicantFirstName,
        last_name: args.ApplicantLastName,
        birth_month: (+args.ApplicantDateOfBirth.split('-')[0]).toString().padStart(2, '0'),
        birth_day: (+args.ApplicantDateOfBirth.split('-')[1]).toString().padStart(2, '0'),
        birth_year: (+args.ApplicantDateOfBirth.split('-')[2]).toString().padStart(4, '0'),
        country: this.googleToCountry(args.ApplicantAddressGoogle) || 'USA', // temp hack, remove 'usa' as default once the sporadic country issue is fixed
        street_address: this.googleFormatStreet(args.ApplicantAddressGoogle),
        street_address_unit: args.ApplicantUnit,
        city: this.googleToCity(args.ApplicantAddressGoogle),
        state: this.googleToState(args.ApplicantAddressGoogle),
        postal_code: this.googleToZip(args.ApplicantAddressGoogle),
        phone1: args.ApplicantPhone.substr(1, 3),
        phone2: args.ApplicantPhone.substr(6, 3),
        phone3: args.ApplicantPhone.substr(10, 4),
        email: args.ApplicantEmail,
        googleCountryMatch: this.googleToCountry(args.ApplicantAddressGoogle),
        googleAddress: args.ApplicantAddressGoogle,
      },
      payment: {
        card_type: this.paymentCreditCardCode(args.PaymentCreditCard),
        card_name: args.PaymentFirstAndLastName,
        card_number: args.PaymentCreditCard,
        cvv: args.PaymentCVC,
        expiration_date: args.PaymentMM + args.PaymentYY,
        postal_code: args.PaymentZip,
      },
      vehicle: {
        year: args.VehicleYear,
        make: args.VehicleMake,
        model: args.VehicleModel,
        vin: args.VehicleVin,
        licenseplate: args.LicensePlate,
        statelicensed: args.SelectedVehicleState,
        countrylicensed: args.SelectedVehicleCountry,
        type: args.VehicleType,
        value: args.SelectedVehicleValue,
      },
      driver: {
        first_name: args.DriverInfoFirstName,
        last_name: args.DriverInfoLastName,
        license_number: args.DriversLicense,
        license_state: args.SelectedDriverInfoState,
        license_country: args.SelectedDriverInfoCountry,
        birth_month: (+args.DriverInfoDateOfBirth.split('-')[0]).toString().padStart(2, '0'),
        birth_day: (+args.DriverInfoDateOfBirth.split('-')[1]).toString().padStart(2, '0'),
        birth_year: (+args.DriverInfoDateOfBirth.split('-')[2]).toString().padStart(4, '0'),
      },
    }

    if ( args.LienholderSelection===true ) {
      payload.lienholder = {
        name: args.LienholderName,
        country: args.LienholderCountry,
        street_address_1: args.LienholderAddress1,
        street_address_2: args.LienholderAddress2,
        city: args.LienholderCity,
        state: args.LienholderState,
        postal_code: args.LienholderPostalCode
      }
    }

    payload.monitorOnly = totalTime>0

    // 120s timeout is more than the aws gateway allows, since it only goes up to 29s, just for safety of individual calls
    axios({
      url: endpoint.url,
      method: 'POST',
      timeout: 60 * 60 * 1000,
      headers: { 'Content-Type': 'application/json', 'x-api-key': endpoint.key },
      data: payload
    }).then(response => {
      onsuccess && onsuccess(response)
    }).catch(error => {
      let elapsedTime = (new Date().getTime() - thisRoundStart)
      // if the current error is due to a long call, at least 25s, then it may be a aws gateway timeout, which maxes at 29s
      // while the actual aws lambda is set up to retry up to 10 minutes, so here I will retry around that time max
      if ( elapsedTime > 25000 && totalTime < 660000 ) {
        // eslint-disable-next-line
        console.log('retrying due to timeout')
        this.submitPayment(endpoint, args, onsuccess, onerror, onany, totalTime + elapsedTime )
      } else {
        onerror && onerror(error)
      }
    }).then(() => {
      onany && onany()
    })
  },

  vinInformation(endpoint, args, onsuccess, onerror, onany) {

    let payload = {
      appid: jslib.detectUrlMexiPolicyProId(),
      vin: args.vin,
      rid: args.rid
    }

    let headers = { headers: { 'Content-Type': 'application/json', 'x-api-key': endpoint.key } }

    axios.post(endpoint.url, payload, headers).then(response => {
      onsuccess && onsuccess(response)
    }).catch(error => {
      onerror && onerror(error)
    }).then(() => {
      onany && onany()
    })
  },

  getRates(endpoint, args, onsuccess, onerror, onany) {
    let payload = {
      appid: jslib.detectUrlMexiPolicyProId(),
      program: args.Program,
      producer_code: args.ProducerCode,
      days_trip: args.SelectedDaysOfCoverage,
      vehicle_value: args.SelectedVehicleValue,
      liability_limit: args.SelectedLiabilityLimit,
      app_record_id: args.RaterApplicationId,
      veh_record_id: args.RaterVehicleId,
      vehicle_primarily_in_mexico: args.VehiclePrimarilyInMexico,
    }

    let headers = { headers: { 'Content-Type': 'application/json', 'x-api-key': endpoint.key } }

    axios.post(endpoint.url, payload, headers ).then(response => {
      onsuccess && onsuccess(response)
    }).catch(error => {
      onerror && onerror(error)
    }).then(() => {
      onany && onany()
    })
  },

  agencyInfo(endpoint, args, onsuccess, onerror, onany) {
    axios.post(endpoint.url,
      {
        appid: jslib.detectUrlMexiPolicyProId(),
        producer_code: args.ProducerCode
      }, {
        headers: { 'Content-Type': 'application/json', 'x-api-key': endpoint.key }
      }).then(response => {
      onsuccess && onsuccess(response)
    }).catch((error) => {
      onerror && onerror(error)
    }).then(()=>{
      onany && onany()
    })
  },

  getContacted(endpoint, args, onsuccess, onerror, onany) {
    axios.post(endpoint.url, {
      appid: jslib.detectUrlMexiPolicyProId(),
      template: 'Contact - buy - producer',
      producer_code: args.ProducerCode,
      variables: {
        producer_code: args.ProducerCode,
        contact_name: args.ContactName,
        contact_info: args.ContactEmailOrPhone
      }
      }, {
        headers: { 'Content-Type': 'application/json', 'x-api-key': endpoint.key }
      }).then(response => {
      onsuccess && onsuccess(response)
    }).catch(error => {
      onerror && onerror(error)
    }).then(() => {
      onany && onany()
    })
  },

  loadTrustPilotWidget(id) {
    if ( document.getElementById(id)!=undefined && window.Trustpilot != undefined ) {
      window.Trustpilot.loadFromElement(document.getElementById(id))
    }
  },

  mapInsuranceCompany(s) {
    if ( s!=undefined && s!=null ) {
      if ( s.toUpperCase().trim()==='AIG' ) {
        return 'aig'
      } else if ( ['ACE', 'CHUBB'].includes(s.toUpperCase().trim()) ) {
        return 'chubb'
      } else {
        return ''
      }
    } 
  },

  interpolate(s, o) {
    if ( s===undefined || s===null ) {
      return s
    }
    let matches
    while ( (matches = s.match(/{{([^}]+)}}/g))!=null && matches.length>0 ) {
      let key = matches[0].substring(2, matches[0].length-2)
      if ( key.substring(0, 1)==='$' ) {
        if ( o[key.substring(1)] != undefined ) {
          s = s.replace('{{' + key + '}}', '$' + o[key.substring(1)].toLocaleString())
        } else {
          break
        }
      } else {
        if ( o[key] != undefined ) {
          s = s.replace('{{' + key + '}}', o[key])
        } else {
          break
        }
      }
    }
    return s
  },

  getAge(dateString) {
    let today = new Date()
    let birthDate = this.toDate(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    let m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m===0 && today.getDate() < birthDate.getDate())) {
      age--
    }
    return age
  },

  dobTodayWithinYearRange(dob, notYoungerThanYears, notOlderThanYears) {
    let age = this.getAge(dob)
    return age >= notYoungerThanYears && age <= notOlderThanYears
  },

  dynamicDecimals(v) {
    v = v.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
    if ( v.endsWith(".00") ) {
      v = v.substr(0, v.length-3)
    }

    return v
  },

  nextRoute($router, liabilityOnly, stamp) {
    for ( let i = 0; i < $router.options.routes.length-1; i++ ) {
      if ( $router.options.routes[i].name===$router.app.$route.name ) {
        do {
          i++
        } while ( (liabilityOnly===true && $router.options.routes[i].onlyIfNotLiabilityOnly===true) || (stamp===true && $router.options.routes[i].notForStamp===true))
        $router.push({ name: $router.options.routes[i].name })
        break
      }
    }
  },

  previousRoute($router, liabilityOnly, stamp) {
    for ( let i = $router.options.routes.length-1; i > 0 ; i-- ) {
      if ( $router.options.routes[i].name===$router.app.$route.name ) {
        do {
          i--
        } while ( (liabilityOnly===true && $router.options.routes[i].onlyIfNotLiabilityOnly===true) || (stamp===true && $router.options.routes[i].notForStamp===true))

        $router.push({ name: $router.options.routes[i].name })
        break
      }
    }
  },

  gaDataLayer(o) {
    if ( dataLayer!=undefined ) { // eslint-disable-line no-undef
      dataLayer.push(o) // eslint-disable-line no-undef
      if ( location.href.toLowerCase().match(/localhost/) || location.href.toLowerCase().match(/staging/) ) {
        // eslint-disable-next-line
        // console.log(o)
      }
    }
  },

  gaRateEvent(screen, startDate, daysOfCoverage, vehicleValue, coverageLevelValue, xdaysCost, annualCost) {
    this.gaDataLayer({
      'screen': screen,
      'policyStartDate': startDate,
      'daysOfCoverage': daysOfCoverage,
      'vehicleValue': vehicleValue,
      'coverageLevel': coverageLevelValue,
      'priceQuotedDays': xdaysCost,
      'priceQuotedAnnual': annualCost,
    })
    this.gaDataLayer({
      'screen': screen,
      'event': 'priceQuoted'
    })
  },

  GetSetProducer(_default) {
    if ( localStorage ) {
      let producer = localStorage.getItem("Producer.Code")!=null && localStorage.getItem("Producer.Code").trim()!='' ? localStorage.getItem("Producer.Code").trim() : _default
      localStorage.setItem('Producer.Code', producer)
      return producer
    } else {
      return _default
    }
  },

  setupTooltips() {
    /*global bootstrap*/
    /*eslint no-undef: "error"*/
    if (this.$tooltips != null) {
      this.$tooltips.map(el => el.dispose())
    }
    this.$tooltips = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]')).map(el => new bootstrap.Tooltip(el))
  }
}
